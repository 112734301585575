import barsAxios from "./api_config";
import {buildBackendUrl} from "../utils/generalUtils";

 const addTest = (data) => {
    return barsAxios.post(buildBackendUrl('/rest/tests'), data);
}

 const getTests = (year = '') => {
    let params = '';

    if (year) {
        params = `?year=${year}`
    }

    return barsAxios.get(buildBackendUrl(`/rest/tests` + params))
}

export default {
    addTest,
    getTests
}