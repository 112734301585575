import barsAxios from "./api_config";
import {buildBackendUrl} from "../utils/generalUtils";

const setConfig = (data) => {
    return barsAxios.post(buildBackendUrl(`/rest/config/multiple`), data)
}

const getTermConfig = () => {
    return barsAxios.get(buildBackendUrl('/rest/config/'));
}

const setConfigValue = (data) => {
    return barsAxios.post(buildBackendUrl('/rest/config'), data)
}

const setPersonalConfig = (data) => {
    return barsAxios.post(buildBackendUrl('/rest/config/personal'), data);
}

const getPersonalConfig = () => {
    return barsAxios.get(buildBackendUrl('/rest/config/personal'));
}

const deletePersonalConfig = (configType) => {
    return barsAxios.delete(buildBackendUrl(`/rest/config/personal/${configType}`))
}

export default {
    setConfig,
    getTermConfig,
    setConfigValue,
    setPersonalConfig,
    getPersonalConfig,
    deletePersonalConfig
}