import api from "../api/api";

export const GET_DEADLINES = "@deadlines/GET_DEADLINES";

export const getDeadlinesData = (type, identifier, planId) => {
    return async (dispatch) => {
        const result = await api.getDeadlines(type, identifier, planId);
        return dispatch({
            type: GET_DEADLINES,
            payload: result.data
        })
    }
}