import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import configReducer from "./configReducer";
import loaderReducer from "./loaderReducer";
import approveStudentsReducer from "./approveStudentsReducer";
import {deadlinesReducer} from "./deadlinesReducer";
import catalogReducer from "./catalogReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  form: formReducer,
  systemConfig: configReducer,
  loader: loaderReducer,
  studentSelections: approveStudentsReducer,
  deadlinesData: deadlinesReducer,
  catalogs: catalogReducer
});

export default rootReducer;
