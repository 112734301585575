import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getPersonalPlan = (id) => {
    return barsAxios.get(buildBackendUrl(`/rest/personal_plans/${id}`))
}

const getPersonalPlanByDisciplineAndGroup = (disciplineId, groupName) => {
    return barsAxios.get(buildBackendUrl(`/rest/personal_plans/${disciplineId}/${groupName}`))
}

const savePersonalPlan = (data) => {
    return barsAxios.post(buildBackendUrl("/rest/personal_plans"), data)
}

export default {
    getPersonalPlan,
    getPersonalPlanByDisciplineAndGroup,
    savePersonalPlan
}