import React from 'react';

function Logo(props) {
    const {dark} = props;
    return (
        <img
            style={{ width: 140 + 'px' }}
            alt="Logo"
            src={"/static/images/logo_osnovnoy_russkiy_belyy.png"}
            {...props}
        />
    );
}

export default Logo;
