import axios from 'axios';
import { logout, setUserData } from "../actions/accountActions";
import { store } from "../store";
import api from "./api";
import { SSO_URL } from "../config";

export const configureAxios = () => {
    let headers = {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token')
    }

    return axios.create({
        withCredentials: true,
        credentials: 'include',
        headers: headers,
        mode: 'cors',
    });
}

const barsAxios = configureAxios();

barsAxios.CancelToken = axios.CancelToken;
barsAxios.isCancel = axios.isCancel;

barsAxios.interceptors.response.use(function (response) {
    const authToken = response.headers.authorization;
    if (authToken && authToken !== "null" && authToken !== "undefined") {
        localStorage.setItem('token', authToken);
    }

    return response;
}, function (error) {
    if (error?.response?.status === 401
        && !window.location.pathname.includes('login')
        && !error.response.config.url.includes("current_user")) {
        api.getCurrentUser().then((response) => {
        }).catch(() => {
            let historyLocation = window.location.pathname;
            if (!window.location.pathname.includes('login')) {
                store.dispatch(logout());
                return window.location.replace(SSO_URL(historyLocation))
            }
        })
    }
    return Promise.reject(error);
})

barsAxios.interceptors.request.use(function (config) {
    const TOKEN = localStorage.getItem('token');
    config.headers.authorization = TOKEN;
    return { ...config };
});

export default barsAxios;

// const backend_address = 'http://195.208.116.213:8080';
// const backend_address = 'http://195.208.116.239:8080'
// const backend_address = 'http://cas.crp.rocks:8080';
// const backend_address = 'http://localhost:8080';
