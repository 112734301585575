import React from 'react';
import PropTypes from 'prop-types';
import FooterComponent from '../../../components/FooterComponent'

Footer.propTypes = {};

function Footer(props) {
    return (
        <div>
            <FooterComponent/>
        </div>
    );
}

export default Footer;