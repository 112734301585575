import produce from "immer";
import {
    SET_SELECTED_STUDENTS,
    SET_SELECTED_STUDENTS_COURSE, SET_SELECTED_STUDENTS_COURSE_RETRY,
    SET_SELECTED_STUDENTS_RETRY
} from "../actions/approveStudentsActions";

const initialState = {
    selectedStudents: [],
    selectedStudentsRetry: []
}

const approveStudentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_STUDENTS: {
            return produce(state, (draft) => {
                draft.selectedStudents = action.payload;
            });
        }
        case SET_SELECTED_STUDENTS_RETRY: {
            return produce(state, (draft) => {
                draft.selectedStudentsRetry = action.payload;
            });
        }
        case SET_SELECTED_STUDENTS_COURSE: {
            return produce(state, (draft) => {
                draft.selectedStudentsCourse = action.payload;
            });
        }
        case SET_SELECTED_STUDENTS_COURSE_RETRY: {
            return produce(state, (draft) => {
                draft.selectedStudentsCourseRetry = action.payload;
            })
        }
        default: {
            return state;
        }
    }
}

export default approveStudentsReducer;