import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getDeadlines = (type, deadlineIdentifier, id) => {
    return barsAxios.get(buildBackendUrl(`/rest/deadline/${type}/${deadlineIdentifier}/${id}`));
}

const getUserDeadlines = () => {
    return barsAxios.get(buildBackendUrl('/rest/deadline'));
}

const postDeadlines = (type, id, data) => {
    return barsAxios.post(buildBackendUrl(`/rest/deadline/${type}/${id}`), data)
}

const postSingleDeadline = (type, identifier, data) => {
    return barsAxios.post(buildBackendUrl(`/rest/deadline/single/${type}/${identifier}`), data)
}

export default {
    getDeadlines,
    postSingleDeadline,
    getUserDeadlines,
    postDeadlines,
}