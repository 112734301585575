import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Message from "./TopBar/Message";
import { useSelector } from "react-redux";
import { getValueFromConfig } from "../../utils/generalUtils";
import CircularLoader from "../../components/loaders/CircularLoader";
import TermChange from "./TopBar/TermChange";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        paddingTop: 104,
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
    }
}));

function DashboardLayout({ children }) {
    const classes = useStyles();
    const { config } = useSelector(state => state.systemConfig);
    const { loader } = useSelector(state => state.loader);
    const message = getValueFromConfig(config, 'daily_message');
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
                <NavBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
                <TermChange/>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        {message && <Message/>}
                        {loader && <CircularLoader/>}
                        {children}

                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

DashboardLayout.propTypes = {
    children: PropTypes.any
};

export default DashboardLayout;
