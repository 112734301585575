import api from "../api/api";

export const CONFIG_REQUEST = '@config/config_request';
export const CONFIG_SUCCESS = '@config/config_success';
export const CONFIG_FAILURE = '@config/config_FAILURE';

export function fetchConfig () {
    return async (dispatch) => {
        try {
            dispatch({ type: CONFIG_REQUEST});

            const config = await api.getTermConfig();

            dispatch({
                type: CONFIG_SUCCESS,
                payload: {
                    config: config.data
                }
            })

        } catch (error) {
            dispatch({ type: CONFIG_FAILURE});
            // throw error;
        }
    }
}