import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import { useSelector } from "react-redux";
import {AlertTriangle} from "react-feather";
import Grid from "@material-ui/core/Grid";
import {getValueFromConfig} from "../../../utils/generalUtils";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        background: '#fff9a6',
        textAlign: 'center',
        borderBottom: '1px solid #d3d3d3',
        padding: 5,
        width:'100%',
    }
}))

const Message = () => {
    const classes = useStyles();
    const {config} = useSelector(state => state.systemConfig);
    const message = getValueFromConfig(config, "daily_message");

    return (
        <div className={classes.root}>
            <Grid container spacing={1} justifyContent={"center"}>
                <Grid item>
                    <AlertTriangle color={'red'}/>
                </Grid>
                <Grid item>
                    <Typography>
                        <b>{message ?? ""}</b>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default Message;