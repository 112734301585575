import React, {useEffect, useRef, useState} from 'react';
import {
    IconButton, makeStyles,
    SvgIcon,
    Tooltip, Typography,
} from '@material-ui/core';
import {HelpCircle} from 'react-feather';
import {useSelector} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {getInstructionLink, getInstructionName} from "../../../utils/generalUtils";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        marginTop: 10 + 'px',
        borderRadius: 12 + 'px'
    },
    disabledLink: {
        pointerEvents: 'none',
        color: "#d3d3d3"
    }
}))

function HelpInfo() {
    const classes = useStyles();
    const {user} = useSelector(state => state.account)
    const userRoleId = user?.selected_role?.id;
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {

    }, [user?.selected_role?.id])

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const instructionLink = getInstructionLink(userRoleId);

    const renderText = (link) => {
        let text = '';
        if (!link) {
            text += '(Отсутствует) '
        }
        text += `Инструкция для ${getInstructionName(userRoleId)}`

        return text;
    }

    return (
        <>
            {user && <Tooltip title="Инструкции">
                <IconButton
                    color="inherit"
                    onClick={handleOpen}
                    ref={ref}
                >
                    <SvgIcon fontSize="small">
                        <HelpCircle/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>}
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>
                    <Typography variant={"h1"}>
                        Инструкции
                    </Typography>
                </DialogTitle>
                <DialogContent>
                        <Grid container classes={{root: classes.root}} direction={"column"}>
                            <Grid item>
                                <Typography variant={"body1"}>
                                    <Link className={instructionLink ? "" : classes.disabledLink} target={"_blank"} href={instructionLink}>
                                        {renderText(instructionLink)}
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant={"contained"}
                        color={"primary"}>
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default HelpInfo;
