import React from "react"
import {
    barsTablesUrl,
    flowLinksUrl,
    deadlinesUrl,
    settingsUrl,
    systemManagementUrl,
    homeUrl,
    adminPanelUrl,
    systemMessagesUrl,
    superAdminPageUrl,
    testUrl,
    intermediateCertificationDateEditorUrl,
    parentsUrl,
    reportsUrl,
    journalExportImport,
    journalPageUrl,
    rpdImportUrl
} from "../../../constants/paths";
import {
    accessEditorName, barsTableName,
    deadlinesName,
    exportImportName, flowConnectionsName,
    journalName, osEditorName,
    reportsName, roleEditorNameName, rpdImportName, systemMessageName, testEditorName, timetableEditorName,
    userChangeName
} from "../../../constants";
import getInitials from "../../../utils/getInitials";
import {
    BookOpen,
    Calendar,
    Edit,
    FileText,
    GitMerge, Layout,
    Lock,
    MessageSquare, PlusCircle,
    RefreshCw,
    ToggleLeft,
    UserPlus, Users
} from "react-feather";

export const adminMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Управление таблицами БаРС',
        items: [
            {
                role: role,
                title: barsTableName,
                href: barsTablesUrl,
                icon: <Layout/>,
                depth: 1
            },
            {
                role: role,
                title: osEditorName,
                href: settingsUrl,
                icon: <PlusCircle/>,
                depth: 2,
            },
            {
                role: role,
                title: testEditorName,
                href: testUrl,
                icon: <PlusCircle/>,
                depth: 3,
            },
            {
                role: role,
                title: rpdImportName,
                href: rpdImportUrl,
                icon: <RefreshCw/>,
                depth: 4,
            }
        ]
    }
}

export const realisatorMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Управление процессом',
        items: [
            {
                role: role,
                title: journalName,
                icon: <BookOpen/>,
                href: journalPageUrl,
                depth: 1
            },
            {
                role: role,
                title: deadlinesName,
                icon: <Calendar/>,
                href: deadlinesUrl,
                depth: 2,
            },
            {
                role: role,
                title: exportImportName,
                href: journalExportImport,
                icon: <RefreshCw/>,
                depth: 3,
            },
            {
                role: role,
                title: reportsName,
                href: reportsUrl,
                icon: <FileText/>,
                depth: 4
            },
        ]
    }
}

export const departmentStaffMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Управление процессом',
        items: [
            {
                role: role,
                title: journalName,
                icon: <BookOpen/>,
                href: journalPageUrl,
                depth: 1
            },
            {
                role: role,
                title: deadlinesName,
                href: deadlinesUrl,
                icon: <Calendar/>,
                depth: 2,
            },
            {
                role: role,
                title: exportImportName,
                href: journalExportImport,
                icon: <RefreshCw/>,
                depth: 3,
            },
            {
                role: role,
                title: reportsName,
                href: reportsUrl,
                icon: <FileText/>,
                depth: 4
            },
        ]
    }
};

export const studentMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Управление процессом (обучающийся)',
        items: [
            {
                role: role,
                title: journalName,
                icon: <BookOpen/>,
                href: journalPageUrl,
                depth: 1
            },
            {
                role: role,
                title: deadlinesName,
                href: deadlinesUrl,
                icon: <Calendar/>,
                depth: 2,
            },
            {
                role: role,
                title: 'Родители',
                href: parentsUrl,
                icon: <Users/>,
                depth: 3
            }
        ]
    }
}

export const mentorMenu = (role) => {
    const mentorMainUserName = ` (${role?.main_user?.last_name} ${getInitials(role?.main_user?.first_name)}.)`;
    return {
        title: role?.name + mentorMainUserName,
        roleId: role?.id,
        role: role,
        subheader: 'Управление процессом (ментор)',
        items: [
            {
                role: role,
                title: journalName,
                href: journalPageUrl,
                icon: <BookOpen/>,
                depth: 1
            },
            {
                role: role,
                title: deadlinesName,
                href: deadlinesUrl,
                icon: <Calendar/>,
                depth: 2,
            },
            {
                role: role,
                title: exportImportName,
                href: journalExportImport,
                icon: <RefreshCw/>,
                depth: 3,
            },
            {
                role: role,
                title: reportsName,
                href: reportsUrl,
                icon: <FileText/>,
                depth: 4
            },
        ]
    }
}

export const curatorMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Управление процессом (куратор)',
        items: [
            {
                role: role,
                title: journalName,
                icon: <BookOpen/>,
                href: journalPageUrl,
                depth: 1
            },
            {
                role: role,
                title: deadlinesName,
                href: deadlinesUrl,
                icon: <Calendar/>,
                depth: 2,
            },
            {
                role: role,
                title: exportImportName,
                href: journalExportImport,
                icon: <RefreshCw/>,
                depth: 3,
            },
            {
                role: role,
                title: reportsName,
                href: reportsUrl,
                icon: <FileText/>,
                depth: 4
            },
        ]
    }
}

export const viceDepartmentStaffMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Управление процессом (замдекана)',
        items: [
            {
                role: role,
                title: journalName,
                href: journalPageUrl,
                icon: <BookOpen/>,
                depth: 1
            },
            {
                role: role,
                title: deadlinesName,
                href: deadlinesUrl,
                icon: <Calendar/>,
                depth: 2,
            },
            {
                role: role,
                title: exportImportName,
                href: journalExportImport,
                icon: <RefreshCw/>,
                depth: 3,
            },
            {
                role: role,
                title: reportsName,
                href: reportsUrl,
                icon: <FileText/>,
                depth: 4
            },
        ]
    }
}

export const parentsMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subHeader: 'Управление процессом (родитель)',
        items: [
            {
                role: role,
                title: journalName,
                href: journalPageUrl,
                icon: <BookOpen/>,
                depth: 1
            },
            {
                role: role,
                title: deadlinesName,
                href: deadlinesUrl,
                icon: <Calendar/>,
                depth: 2,
            },
        ]
    }
}

export const dodMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Администрирование',
        items: [
            {
                role: role,
                title: accessEditorName,
                href: systemManagementUrl,
                icon: <Lock/>,
                depth: 1,
            },
            {
                role: role,
                title: flowConnectionsName,
                href: flowLinksUrl,
                icon: <GitMerge/>,
                depth: 2
            },
            {
                role: role,
                title: timetableEditorName,
                href: intermediateCertificationDateEditorUrl,
                icon: <Edit/>,
                depth: 4
            },
            {
                role: role,
                title: reportsName,
                href: reportsUrl,
                icon: <FileText/>,
                depth: 5
            },
            {
                role: role,
                title: roleEditorNameName,
                href: adminPanelUrl,
                icon: <UserPlus/>,
                depth: 6
            },
            {
                role: role,
                title: systemMessageName,
                href: systemMessagesUrl,
                icon: <MessageSquare/>,
                depth: 7
            },
        ]
    }
}

export const officeManagerMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Суперпользователь',
        items: [
            {
                role: role,
                title: userChangeName,
                href: superAdminPageUrl,
                icon: <ToggleLeft/>,
                depth: 1
            },
        ],
    }
}

export const superAdminMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        subheader: 'Суперпользователь',
        items: [
            {
                role: role,
                title: userChangeName,
                href: superAdminPageUrl,
                icon: <ToggleLeft/>,
                depth: 1
            },
        ],
    }
}

export const defaultMenu = (role) => {
    return {
        title: role?.name,
        roleId: role?.id,
        role: role,
        items: [
            {
                role: role,
                title: "Главная страница",
                href: homeUrl,
                depth: 1
            }
        ]
    }
}