export const SET_SELECTED_STUDENTS  = "@selectedStudents/SET_SELECTED_STUDENTS";
export const SET_SELECTED_STUDENTS_RETRY = "@selectedStudents/SET_SELECTED_STUDENTS_RETRY";

export const SET_SELECTED_STUDENTS_COURSE  = "@selectedStudents/SET_SELECTED_STUDENTS_COURSE";
export const SET_SELECTED_STUDENTS_COURSE_RETRY  = "@selectedStudents/SET_SELECTED_STUDENTS_COURSE_RETRY";

export const GET_SELECTED_STUDENTS = "@selectedStudents/GET_SELECTED_STUDENTS";
export const GET_SELECTED_STUDENTS_RETRY = "@selectedStudents/GET_SELECTED_STUDENTS_RETRY";

export const setSelectedStudents = (data) => {
    return async (dispatch) => dispatch ({
        type: SET_SELECTED_STUDENTS,
        payload: data
    })
}

export const setSelectedStudentsRetry = (data) => {
    return async (dispatch) => dispatch ({
        type: SET_SELECTED_STUDENTS_RETRY,
        payload: data
    })
}

export const setSelectedStudentCourse = (data) => {
    return async (dispatch) => dispatch ({
        type: SET_SELECTED_STUDENTS_COURSE,
        payload: data
    })
}

export const setSelectedStudentCourseRetry = (data) => {
    return async (dispatch) => dispatch ({
        type: SET_SELECTED_STUDENTS_COURSE_RETRY,
        payload: data
    })
}