export default {
  h1: {
  fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 36,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontFamily: 'Muller',
    fontWeight: 700,
    fontSize: 32,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontFamily: 'Muller',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 28,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontFamily: 'Muller',
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px'
  },
  body1: {
    fontFamily: 'ALS Schlange sans',
    fontWeight: 'bold',
    fontSize: 18,
  },
  body2: {
    fontFamily: 'ALS Schlange sans',
    fontWeight: 'bold',
    fontSize: 14,
  },
  body3: {
    fontFamily: 'ALS Schlange sans',
    fontWeight: 400,
    fontSize: 16
  },
  overline: {
    fontWeight: 500
  },
};
