import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Link, Typography } from "@material-ui/core";
import { techSupportLink } from "../constants";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: ' #0b68fe',
        padding: 5,
        position: "absolute",
        zIndex: 100,
    },
    footerText: {
        fontSize: 13,
        color: "white",
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    footerTextContainer: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    mobileImageContainer: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    imageContainer: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    logoDesktopImage: {
        width: '40%',
        maxWidth: '200px',
        minWidth: '150px'
    },
    logoMobileImage: {
        width: '65%',
        maxWidth: '230px',
        minWidth: '200px'
    }
}))

const FooterComponent = (props) => {
    const classes = useStyles();
    const usageInfoPdfLink = 'https://itmo.ru/images/pages/79/Pravila_ispolzovanija_informacii.pdf';
    const personalInfoPdfLink = 'https://itmo.ru/file/pages/79/personal_data_policy.pdf';

    return (
        <footer>
            <Grid className={classes.root} container justifyContent={"space-around"} alignContent={"center"}
                  alignItems={"center"}>
                <Grid className={classes.mobileImageContainer} item xs={5} sm={5} md={3} justifyContent={"center"}
                      alignContent={"center"} alignItems={"center"}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img src={'/static/images/slogan_belyy.png'} alt={"img"} className={classes.logoMobileImage}/>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Grid container direction={"column"} alignItems={"flex-start"}
                          alignContent={"center"}>
                        <Grid item xs={12} md={12}>
                            <Typography
                                className={classes.footerText}
                                align={"left"}
                                variant={"body1"}>
                                <Link className={classes.footerText} style={{ textDecoration: "underline" }}
                                      target={"_blank"} href={techSupportLink}>
                                    Тех. поддержка тут</Link> и в ауд. 1340, Кронверкский, 49 (по будням с 9 до 19)
                            </Typography>
                            <Typography
                                className={classes.footerText}
                                align={"left"}
                                variant={"body1"}>
                                Информация © 1993–{new Date().getFullYear()} Университет ИТМО
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.imageContainer} xs={5} sm={5} md={3} justifyContent={"center"}
                      alignContent={"center"} alignItems={"center"}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img src={'/static/images/slogan_belyy.png'} alt={"img"} className={classes.logoDesktopImage}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container direction={"column"} alignItems={"flex-end"} className={classes.footerTextContainer}
                          alignContent={"center"}>
                        <Grid item>
                            <Typography
                                className={classes.footerText}
                                align={"right"}
                                variant={"body1"}
                                color={"textSecondary"}>
                                <Link className={classes.footerText} style={{ textDecoration: "underline" }}
                                      target={"_blank"} href={usageInfoPdfLink}>
                                    Правила использования информации в доменной зоне itmo.ru</Link>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={classes.footerText}
                                align={'right'}
                                variant={"body1"}
                                color={"textSecondary"}>
                                <Link className={classes.footerText} style={{ textDecoration: "underline" }}
                                      target={"_blank"} href={personalInfoPdfLink}>
                                    Политика по обработке Персональных данных</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </footer>
    );
}

FooterComponent.propTypes = {};

export default FooterComponent;