import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    ButtonBase,
    Typography,
} from '@material-ui/core';
import {mentorId, parentId} from "../../../constants";
import getInitials from "../../../utils/getInitials";
import {makeStyles} from "@material-ui/styles";
import api from "../../../api/api";
import { setUserData } from "../../../actions/accountActions";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
    text: {
        fontSize: 13,
        whiteSpace: 'nowrap',
        fontFamily: 'ALS Schlange sans',
        '@media(max-width: 550px)': {
            fontSize: 11
        }
    }
}))

function Account() {
    const ref = useRef(null);
    const {user} = useSelector((state) => state.account);
    const account = useSelector((state) => state.account);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const userRoleId = user?.selected_role?.id;
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentUser(user);
    }, [user]);

    // useEffect(() => {
    //     if (user === null && !loading) {
    //         checkUser()
    //     }
    // }, [])

    const checkUser = () => {
        setLoading(true);
        api.getCurrentUser()
            .then((response) => {
            dispatch(setUserData(response.data))
            setCurrentUser(response.data)
        }).catch(() => {
            // localStorage.setItem('token', '');
            // history.push('/')
        }).finally(() => {
            setLoading(false);
        });
    }

    const getNameWithInitials = (mainUser) => {
        let result = '';

        switch(userRoleId) {
            case parentId:
                result += 'Родитель';
                break;
            case mentorId:
                result += 'Ментор';
                break;
            default:
                break;
        }

        if (mainUser?.last_name) {
            result += ` ${mainUser?.last_name}`;
        }

        if (mainUser?.first_name) {
            result += ` ${getInitials(mainUser?.first_name)}.`
        }

        if (mainUser?.middle_name) {
            result += ` ${getInitials(mainUser?.middle_name)}.`
        }

        return result;
    }

    const renderName = () => {
        if (currentUser) {
            const {selected_role} = currentUser;
            const main_user = selected_role?.main_user;

            if (userRoleId === parentId) {
                return getNameWithInitials(main_user);
            }

            if (userRoleId === mentorId) {
                return getNameWithInitials(main_user);
            }

            return getNameWithInitials(user)
        }
    }

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                ref={ref}
            >
                <Typography
                    variant="h6"
                    color="inherit"
                    classes={{root: classes.text}}
                >
                    {currentUser && renderName()}
                </Typography>
            </Box>
        </>
    );
}

export default Account;
