import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Button,
    Grid,
    makeStyles, Tooltip
} from '@material-ui/core';
import api from "../../../api/api";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../actions/accountActions";
import { disableLoader, enableLoader } from "../../../actions/loaderActions";

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        fontSize: 10,
        paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        fontSize: 14,
        fontFamily: 'ALS Schlange Sans',
        color: 'white',
        padding: '10px 8px',
        justifyContent: 'center',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%'
    },
    buttonLeaf: {
        fontSize: 14,
        fontFamily: 'ALS Schlange Sans',
        color: 'black',
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium
            }
        }
    },
    tooltip: {
        backgroundColor: "white",
        boxShadow: '1px 1px 4px 1px #545454'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto'
    },
    active: {
        color: '#b3b3b3',
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '& $icon': {
            color: theme.palette.secondary.main
        }
    }
}));

const NavItem = ({
                     title,
                     href,
                     depth,
                     icon: Icon,
                     className,
                     children,
                     role,
                     open: openProp,
                     info: Info,
                     tooltipPlacement,
                     style,
                     ...rest
                 }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);
    const [, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    let childrenArr = React.Children.toArray(children);

    const handleToggle = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setAnchorEl(null);
    }

    const onMenuItemClick = (role) => {
        setOpen(false);
        history.push(href);
        if (history.location.pathname.includes(href)) {
            dispatch(enableLoader());
            api.setUserRole(role)
                .then(() => {
                    dispatch(setUserData());
                    setTimeout(() => {
                        history.go();
                        dispatch(disableLoader())
                    }, 700)
                })
                .catch(() => dispatch(disableLoader()))
        }
    }

    if (children) {
        return (
            <Grid item
                  className={clsx(classes.item, className)}
                  disableGutters
                  key={title}
                  {...rest}
            >
                <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    placement={tooltipPlacement}
                    open={open}
                    interactive={true}
                    onClose={onClose}
                    title={
                        <Grid container direction={"column"}>
                            {childrenArr[0]?.props?.children?.filter(x => x).map(child => {
                                return <Grid item onClick={onClose}>{child}</Grid>
                            })}
                        </Grid>
                    }>
                    <Button
                        className={classes.button}
                        onMouseOver={handleToggle}
                        style={style}
                    >
          <span className={classes.title}>
            {title}
          </span>
                    </Button>
                </Tooltip>
            </Grid>
        );
    }

    return (
        <Grid item
              lg={12}
              className={clsx(classes.itemLeaf, className)}
              disableGutters
              key={title}
              {...rest}
        >
            <Button
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                onClick={() => onMenuItemClick(role)}
                style={style}
            >
        <span className={classes.title}>
          {title}
        </span>
                {Info && <Info className={classes.info}/>}
            </Button>
        </Grid>
    );
}

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.any,
    info: PropTypes.any,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
    open: false
};

export default NavItem;
