import barsAxios from "./api_config";
import { buildBackendUrl } from "../utils/generalUtils";

let groupsRequest = null;

const getGroups = () => {
    return barsAxios.get(buildBackendUrl(`/rest/groups`));
}

const getGroupsWithDisciplineId = (disciplineId) => {
    return barsAxios.get(buildBackendUrl(`/rest/disciplines/${disciplineId}/group`))
}

const getGroupsAndFlowsNames = (id) => {
    return barsAxios.get(buildBackendUrl(`/rest/checkpoint_plans/${id}/group_and_flow_names`))
}

const getGroupsAndFlows = ({ disciplineId = '', checkpointPlanId = '', name = '' }) => {
    const params = [];
    if (disciplineId) {
        params.push(`disciplineId=${disciplineId}`)
    }
    if (checkpointPlanId) {
        params.push(`checkpointPlanId=${checkpointPlanId}`)
    }
    if (name) {
        params.push(`name=${name}`)
    }
    if (groupsRequest) {
        groupsRequest.cancel();
    }

    groupsRequest = barsAxios.CancelToken.source();

    return barsAxios.get(buildBackendUrl(`/rest/journal/groups-and-flows?` + params.join('&')), { cancelToken: groupsRequest.token })
}

export default {
    getGroups,
    getGroupsWithDisciplineId,
    getGroupsAndFlowsNames,
    getGroupsAndFlows
}