import barsAxios from "./api_config";
import {buildBackendUrl} from "../utils/generalUtils";

const getCheckpointTypes = (name, type) => {
    const params = [];
    if (name) {
        params.push(`name=${name}`);
    }

    if (type) {
        params.push(`type=${type}`);
    }

    const paramsString = params.join('&');
    return barsAxios.get(buildBackendUrl(`/rest/checkpoint_types?` + paramsString));
}

const createCheckpointType = (name, type) => {
    let data = {'name': name}
    return barsAxios.post(buildBackendUrl(`/rest/checkpoint_types/${type}`), data);
}

export default {
    getCheckpointTypes,
    createCheckpointType
}