import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    MenuItem, Menu, Grid, Tooltip
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Logout from "./Logout";
import { barsDashboard } from "../../../constants/paths";
import { useDispatch, useSelector } from "react-redux";
import HelpInfo from "./HelpInfo";
import { MoreHoriz } from "@material-ui/icons";
import SuperAdminReturn from "./SuperAdminReturn";
import { navRouteSelect } from "../../../utils/generalUtils";
import { matchPath, useHistory } from "react-router";
import NavItem from "../NavBar/NavItem";
import { fetchConfig } from "../../../actions/configActions";
import { ChevronDown } from "react-feather";
import { useIsDesktopSize } from "../../../hooks/useDesktopSize";

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(270deg, #0b68fe 34.84%, #7F00FF 75.68%)',
        zIndex: theme.zIndex.drawer + 100,
        ...theme.name === THEMES.LIGHT ? {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main
        } : {},
        ...theme.name === THEMES.ONE_DARK ? {
            backgroundColor: theme.palette.background.default
        } : {}
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        justifyItems: 'center',
        minHeight: 64,
    },
    tooltip: {
        color: 'black',
        backgroundColor: "white",
        boxShadow: '1px 1px 5px 0px black',
        '& button': {
            color: 'black',
            fontWeight: 400
        }
    },
}));

function TopBar({
                    className,
                    onMobileNavOpen,
                    ...rest
                }) {
    const classes = useStyles();
    const { user } = useSelector(state => state.account);
    const { config } = useSelector(state => state.systemConfig);
    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);

    const [underMenuOpen, setUnderMenuOpen] = useState(false);
    const [underMenuAnchor, setUnderMenuAnchor] = useState(null);
    const [menu, setMenu] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const isDesktopSize = useIsDesktopSize();

    useEffect(() => {
        if (!config) {
            dispatch(fetchConfig())
        }

    }, [user])

    useEffect(() => {
        if (user) {
            setMenu(renderNavItems({
                    items: navRouteSelect(user?.user_roles),
                    parent: true, pathname: location.pathname
                }
            ))
        }
    }, [user, isDesktopSize, underMenuOpen])

    const handleUnderMenuOpen = (e) => {
        e.stopPropagation()
        setUnderMenuAnchor(e.currentTarget)
        setUnderMenuOpen(true)
    }

    const handleUnderMenuClose = () => {
        setUnderMenuAnchor(null);
        setUnderMenuOpen(false);
    }

    const handleOpen = (e) => {
        setAnchor(e.currentTarget)
        setOpen(true)
    }

    const handleClose = () => {
        setAnchor(null);
        setOpen(false);
    }

    function renderNavItems({ items, role, parent, ...rest }) {
        let underMenuItems = [...items];
        let menuItemsNumber = isDesktopSize ? 4 : 0
        if (parent) {
            underMenuItems = items.splice(menuItemsNumber);
        }

        return (
            <Grid container justifyContent={"flex-end"}>
                {items.reduce(
                    (acc, item) => reduceChildRoutes({ acc, item, role, ...rest }),
                    []
                )}
                {parent
                    && items?.length >= menuItemsNumber
                    && underMenuItems?.length > 0
                    && <Grid item>
                        <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            open={underMenuOpen}
                            leaveDelay={200}
                            interactive={true}
                            title={
                                <Grid container direction={"column"} onClick={handleUnderMenuClose}>
                                    {underMenuItems.reduce(
                                        (acc, item) => reduceChildRoutes({
                                            acc, item, role, tooltipPlacement: 'left-start', ...rest
                                        })
                                        , []
                                    )}
                                </Grid>
                            }
                            anchorEl={underMenuAnchor} onClose={handleUnderMenuClose}>
                            <IconButton color={"inherit"} onMouseOver={handleUnderMenuOpen}>
                                <ChevronDown/>
                            </IconButton>
                        </Tooltip>
                    </Grid>}
            </Grid>
        );
    }

    const selectedMenuItem = (item) => {
        return user?.selected_role?.id === item?.role?.id
        && user?.selected_role?.main_user?.id === item?.role?.main_user?.id
            ? { fontWeight: '900' }
            : { fontWeight: '400' }
    }

    const selectedSubMenuItem = (item) => {
        return user?.selected_role?.id === item?.role?.id
        && user?.selected_role?.main_user?.id === item?.role?.main_user?.id
        && history.location?.pathname.includes(item.href)
            ? { fontWeight: '900' }
            : { fontWeight: '400' }
    }

    function reduceChildRoutes({ acc, pathname, item, role, tooltipPlacement, depth = 0 }) {
        const key = item?.title + depth;

        if (item?.items) {
            const open = matchPath(pathname, {
                path: item?.href,
                exact: false
            });

            acc.push(
                <NavItem
                    depth={depth}
                    icon={item.icon}
                    style={selectedMenuItem(item)}
                    role={item.role ?? role}
                    tooltipPlacement={tooltipPlacement}
                    key={key}
                    info={item.info}
                    open={Boolean(open)}
                    title={item.title}
                >
                    {renderNavItems({
                        depth: depth + 1,
                        role: item.role,
                        pathname,
                        items: item.items
                    })}
                </NavItem>
            );
        } else {
            acc.push(
                <NavItem
                    depth={depth}
                    href={item?.href}
                    icon={item?.icon}
                    role={item?.role}
                    style={selectedSubMenuItem(item)}
                    key={key}
                    info={item?.info}
                    title={item?.title}
                />
            );
        }

        return acc;
    }

    return (
        <AppBar
            position={"fixed"}
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <RouterLink to={barsDashboard}>
                    <Logo dark={"true"}/>
                </RouterLink>
                <Box width={"75%"}>
                    <Grid container direction={'row'}>
                        {menu}
                    </Grid>
                </Box>
                <Box display={'flex'} alignContent={'flex-start'} flexWrap={'nowrap'} flexDirection={'row'}>
                    <Account/>
                    <Hidden mdDown>
                        <Box width={70}>
                            <HelpInfo/>
                            {user?.can_change_user
                                && user?.super_user_personal_number !== user?.login
                                && <SuperAdminReturn/>}
                            <Logout/>
                        </Box>
                    </Hidden>
                </Box>
                <Hidden lgUp>
                    <IconButton color={"inherit"} onClick={handleOpen}>
                        <MoreHoriz/>
                    </IconButton>
                    <Menu
                        anchorEl={anchor}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem>
                            <HelpInfo/>
                        </MenuItem>
                        {user?.can_change_user
                            && user?.super_user_personal_number !== user?.login
                            && <MenuItem>
                                <SuperAdminReturn/>
                            </MenuItem>}
                        <MenuItem>
                            <Logout/>
                        </MenuItem>
                    </Menu>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
