import api from "../api/api";

export const GET_DISCIPLINES = '@catalog/GET_DISCIPLINES';
export const GET_USER_DISCIPLINES = '@catalog/GET_USER_DISCIPLINES';
export const SET_USER_DISCIPLINES = '@catalog/SET_USER_DISCIPLINES';
export const GET_STORED_DISCIPLINES = "@catalog/GET_STORED_DISCIPLINES";

export const fetchAllDisciplines = (search = '', type = '', identifier = '', excludeIds = []) => {
    return async (dispatch) => {
        const disciplines = await api.getAllDisciplines(search, type, identifier, excludeIds);
        dispatch({
            type: GET_DISCIPLINES,
            payload: disciplines.data
        })
    }
}

export const fetchUserDisciplines = ({
                                         name = '',
                                         identifier = '',
                                         withCheckpointPlansOnly = false,
                                         distinctByCheckpointPlans = false,
                                         type = ''
                                     }) => {
    return async (dispatch) => {
        api.getUserDisciplines({
            name: name,
            identifier: identifier,
            withCheckpointPlansOnly: withCheckpointPlansOnly,
            distinctByCheckpointPlans: distinctByCheckpointPlans,
            type: type
        }).then((response) => {
            dispatch({
                type: GET_USER_DISCIPLINES,
                payload: response.data
            })
        });
    }
}

export const getCachedUserDisciplines = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_STORED_DISCIPLINES,
            payload: JSON.parse(localStorage.getItem("cached_disciplines"))
        })
    }
}

export const setUserDisciplines = (data, role) => {
    return async (dispatch) => {
        dispatch({
            type: SET_USER_DISCIPLINES,
            payload: { role: role, data: data }
        })
    }
}