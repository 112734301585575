/* eslint-disable react/no-array-index-key */
import React, {
    lazy,
    Suspense,
    Fragment
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import {
    barsPlanConstructor,
    barsTablesUrl,
    flowLinksUrl,
    deadlinesUrl,
    disciplinesPageUrl,
    journalPageUrl,
    parentsUrl,
    personalPlanUrl,
    settingsUrl,
    systemManagementUrl,
    homeUrl,
    adminPanelUrl,
    barsDashboard,
    systemMessagesUrl,
    superAdminPageUrl,
    testUrl, intermediateCertificationDateEditorUrl, reportsUrl, journalExportImport, rpdImportUrl
} from "./constants/paths";
import {
    curatorId, departmentStaffId,
    dodAdminId, mentorId,
    parentId,
    studentId,
    superAdminId,
    teacherAdminId,
    teacherRealisatorId, viceDepartmentStaffId
} from "./constants";

const routesConfig = [
    {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login"/>
    },
    {
        exact: true,
        path: '/rest/login',
        component: lazy(() => import('src/views/auth/LoginView/RedirectPageView'))
    },
    {
        exact: true,
        path: '/rest/login/:?customRedirectUri',
        component: lazy(() => import('src/views/auth/LoginView/RedirectPageView'))
    },
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/pages/Error404View'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/register/:token',
        component: lazy(() => import('src/views/auth/RegisterView'))
    },
    {
        exact: true,
        path: '/login-unprotected',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        path: '/app',
        component: () => <Redirect to={barsDashboard}/>
    },
    {
        path: '/bars',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/bars',
                component: () => <Redirect to={barsDashboard}/>
            },
            {
                exact: true,
                path: homeUrl,
                component: lazy(() => import('src/views/home'))
            },
            {
                exact: true,
                path: barsDashboard,
                component: lazy(() => import('src/views/reports/DashboardView'))
            },
            {
                exact: true,
                path: barsTablesUrl,
                access: [teacherAdminId],
                guard: AuthGuard,
                component: lazy(() => import('src/views/bars_tables/BarsTablesView'))
            },
            {
                exact: true,
                path: rpdImportUrl,
                access: [teacherAdminId],
                guard: AuthGuard,
                component: lazy(() => import('src/views/rpd_import/'))
            },
            {
                exact: true,
                guard: AuthGuard,
                access: [teacherRealisatorId, departmentStaffId, studentId, parentId, mentorId, curatorId, viceDepartmentStaffId],
                path: deadlinesUrl,
                component: lazy(() => import('src/views/deadlines'))
            },
            {
                exact: true,
                access: [teacherAdminId, departmentStaffId, teacherRealisatorId],
                guard: AuthGuard,
                path: `${barsPlanConstructor}:mode/:id`,
                component: lazy(() => import('src/views/bars_plan_constructor/BarsPlanConstructor'))
            },
            {
                exact: true,
                guard: AuthGuard,
                access: [studentId, departmentStaffId, teacherRealisatorId, parentId, mentorId, curatorId, viceDepartmentStaffId],
                path: disciplinesPageUrl,
                component: lazy(() => import('src/views/disciplines'))
            },
            {
                exact: true,
                path: adminPanelUrl,
                access: [dodAdminId],
                guard: AuthGuard,
                component: lazy(() => import('src/views/dod_admin/AdminView'))
            },
            {
                exact: true,
                path: systemMessagesUrl,
                access: [dodAdminId],
                guard: AuthGuard,
                component: lazy(() => import('src/views/dod_admin/SystemMessagesView'))
            },
            {
                exact: true,
                guard: AuthGuard,
                access: [studentId, departmentStaffId, teacherRealisatorId, parentId, mentorId, curatorId, viceDepartmentStaffId],
                path: `${journalPageUrl}:planId/:disciplineId/:type/:deadlineIdentifier`,
                component: lazy(() => import('src/views/journal'))
            },
            {
                exact: true,
                guard: AuthGuard,
                access: [studentId, departmentStaffId, teacherRealisatorId, parentId, mentorId, curatorId, viceDepartmentStaffId],
                path: `${journalPageUrl}:disciplineId/:type/:deadlineIdentifier`,
                component: lazy(() => import('src/views/journal'))
            },
            {
                exact: true,
                guard: AuthGuard,
                access: [studentId, departmentStaffId, teacherRealisatorId, parentId, mentorId, curatorId, viceDepartmentStaffId],
                path: `${journalPageUrl}`,
                component: lazy(() => import('src/views/journal'))
            },
            {
                exact: true,
                access: [teacherAdminId],
                guard: AuthGuard,
                path: settingsUrl,
                component: lazy(() => import('src/views/settings/SettingsView'))
            },
            {
                exact: true,
                access: [teacherAdminId],
                guard: AuthGuard,
                path: testUrl,
                component: lazy(() => import('src/views/settings/AddTestView'))
            },
            {
                exact: true,
                access: [teacherAdminId],
                guard: AuthGuard,
                path: `${barsPlanConstructor}:mode/`,
                component: lazy(() => import('src/views/bars_plan_constructor/BarsPlanConstructor'))
            },
            {
                exact: true,
                guard: AuthGuard,
                access: [teacherRealisatorId, departmentStaffId],
                path: `${personalPlanUrl}:mode/:type/:deadline_id/:discipline_id/:checkpoint_plan_id`,
                component: lazy(() => import('src/views/personal_plan/PersonalPlan'))
            },
            {
                exact: true,
                guard: AuthGuard,
                access: [teacherRealisatorId, departmentStaffId, mentorId, curatorId, viceDepartmentStaffId],
                path: journalExportImport,
                component: lazy(() => import('src/views/journal_export_import/'))
            },
            {
                exact: true,
                path: parentsUrl,
                guard: AuthGuard,
                access: [studentId],
                component: lazy(() => import('src/views/parents/ParentsView'))
            },
            {
                exact: true,
                path: systemManagementUrl,
                access: [dodAdminId],
                guard: AuthGuard,
                component: lazy(() => import('src/views/dod_admin/SystemManagementView'))
            },
            {
                exact: true,
                path: flowLinksUrl,
                access: [dodAdminId],
                guard: AuthGuard,
                component: lazy(() => import('src/views/dod_admin/flow_connections/FlowConnectionListView'))
            },
            {
              exact: true,
              path: superAdminPageUrl,
              access: [superAdminId],
                guard: AuthGuard,
                component: lazy(() => import('src/views/superadmin/SuperAdminView'))
            },
            {
                exact: true,
                access: [dodAdminId],
                guard: AuthGuard,
                path: `${flowLinksUrl}add`,
                component: lazy(() => import('src/views/dod_admin/flow_connections/FlowConnectionEditView'))
            },
            {
                exact: true,
                access: [dodAdminId],
                guard: AuthGuard,
                path: intermediateCertificationDateEditorUrl,
                component: lazy(() => import('src/views/dod_admin/IntermediateСertificationDateEditorView'))
            },
            {
                exact: true,
                access: [dodAdminId, teacherRealisatorId, curatorId, mentorId, departmentStaffId, viceDepartmentStaffId],
                guard: AuthGuard,
                path: reportsUrl,
                component: lazy(() => import('src/views/dod_admin/ReportsView'))
            },
            {
                component: () => <Redirect to="/404"/>
            }
        ]
    },
];

const GuardReplacer = (props) => {
    return <div>{props.children}</div>
}

const renderRoutes = (routes) => (routes ? (
    <Suspense fallback={<LoadingScreen/>}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || GuardReplacer;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Fragment>
                            <Guard ids={route.access}>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                            </Fragment>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
) : null);

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
