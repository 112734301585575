import {GET_DEADLINES} from "../actions/deadlinesActions";
import produce from "immer";

const initialState = {
    deadlines: []
}

export const deadlinesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DEADLINES:
            return produce(state, (draft) => {
                draft.deadlines = action.payload;
            })
        default:
            return state;
    }
}