import React, {useRef} from 'react';
import {IconButton, SvgIcon, Tooltip} from "@material-ui/core";
import {UserX} from "react-feather";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import api from "../../../api/api";
import {toast} from "react-toastify";
import {generalMessages} from "../../../constants/messages";

const SuperAdminReturn = () => {
    const history = useHistory();
    const {user} = useSelector(state => state.account)
    const ref = useRef(null);

    const handleReturn = () => {
        const personalNumber = user?.super_user_personal_number;
        if (personalNumber) {
            api.setUser(personalNumber)
                .then(() => {
                    history.push('/bars/default');
                    history.go();
                })
                .catch(() => {
                    toast.error(generalMessages.PROCESSING_ERROR)
                })
        } else {
            toast.error("Отсутвует персональный номер пользователя")
        }
    }

    return (
        <>
            {user && <Tooltip title="Вернуться">
                <IconButton
                    color="inherit"
                    onClick={handleReturn}
                    ref={ref}
                >
                    <SvgIcon fontSize="small">
                        <UserX/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>}
        </>
    );
}

export default SuperAdminReturn;