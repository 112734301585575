import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
    createStyles,
    jssPreset,
    makeStyles,
    StylesProvider,
    ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/ru'
import * as moment from "moment";
import ToastDefaultContainer from "./components/ToastDefaultContainer";

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
moment.locale('ru')

const useStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%'
        },
        '#root': {
            height: '100%',
            width: '100%',
        },
        '.MuiIconButton-root': {
            padding: 7
        }
    }
}));

function App() {
    useStyles();

    const { settings } = useSettings();

    return (
        <ThemeProvider theme={createTheme(settings)}>
            <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider maxSnack={1}>
                        <Router history={history}>
                            <ScrollReset/>
                            <Routes/>
                            <ToastDefaultContainer/>
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}

export default App;
