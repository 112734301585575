import { useEffect, useState } from "react";

function testIsDesktop() {
    if (typeof window === 'undefined') {
        return true;
    }
    return window.innerWidth >= 950;
}

export function useIsDesktopSize() {
    const [isDesktopSize, setIsDesktopSize] = useState(testIsDesktop);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        function autoResize() {
            setIsDesktopSize(testIsDesktop());
        }

        window.addEventListener('resize', autoResize);

        autoResize();

        return () => window.removeEventListener('resize', autoResize);
    }, [])

    return isDesktopSize;
}