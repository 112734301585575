import React, {forwardRef} from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {
    adminMenu, curatorMenu, departmentStaffMenu,
    dodMenu, mentorMenu,
    parentsMenu,
    realisatorMenu,
    studentMenu,
    superAdminMenu,
    viceDepartmentStaffMenu,
    officeManagerMenu
} from "../layouts/DashboardLayout/NavBar/menus";

export const SSO_LOGOUT_URL = process.env.REACT_APP_SSO_LOGOUT_URI;

export const terms = [
    { id: 0, value: "0", name: "Весенний семестр" },
    { id: 1, value: "1", name: "Осенний семестр" }
];

export const disciplineTermsList = [
    {id: 0, value: 1, name: 'Семестр 1'},
    {id: 1, value: 2, name: 'Семестр 2'},
    {id: 2, value: 3, name: 'Семестр 3'},
    {id: 3, value: 4, name: 'Семестр 4'},
    {id: 4, value: 5, name: 'Семестр 5'},
    {id: 5, value: 6, name: 'Семестр 6'},
    {id: 6, value: 7, name: 'Семестр 7'},
    {id: 7, value: 8, name: 'Семестр 8'},
    {id: 8, value: 9, name: 'Семестр 9'},
    {id: 9, value: 10, name: 'Семестр 10'},
]

export const edit_bars = "edit_bars"
export const edit_regular = "edit_regular"
export const edit_journal = "edit_journal"
export const edit_exams = "edit_exams"
export const edit_personal = "edit_personal"
export const current_year = "current_year"
export const current_term = "current_term"

export const THEMES = {
    LIGHT: 'LIGHT',
    // ONE_DARK: 'ONE_DARK',
    // UNICORN: 'UNICORN'
};

export const finalsTypes = [
    {
        label: "Экзамен",
        value: 1
    },
    {
        label: "Зачет",
        value: 2
    },
    {
        label: "Диф. зачет",
        value: 3
    }
]

export const contactEmail = "bars@itmo.ru";
export const techSupportLink = "https://helpdesk.itmo.ru/servicedesk/customer/portal/10/group/111"

export const checkpointGeneralType = [
    {id: "regular", name: "Тип ОС для стандартной КТ"},
    {id: "final", name: "Тип ОС для промежуточной аттестации"},
    {id: "course", name: "Тип ОС для курсового проекта"}
]

export const planStatus = [
    {
        value: "SAVED",
        label: "Сохранено"
    },
    {
        value: "SENT",
        label: "Отправлено"
    }
]

export const teacherAdminId = 1;
export const teacherRealisatorId = 2;
export const studentId = 3;
export const parentId = 4;
export const didAdminId = 6;
export const dodAdminId = 7;
export const superAdminId = 8;
export const mentorId = 9;
export const curatorId = 10;
export const departmentStaffId = 11;
export const viceDepartmentStaffId = 12;
export const officeManager = 13;

export const userRoles = [
    {
        id: teacherAdminId,
        name: "Преподаватель-Администратор",
        menu: adminMenu
    },
    {
        id: teacherRealisatorId,
        name: "Преподаватель-Реализатор",
        menu: realisatorMenu
    },
    {
        id: studentId,
        name: "Ученик",
        menu: studentMenu
    },
    {
        id: parentId,
        name: "Родитель",
        menu: parentsMenu
    },
    {
        id: dodAdminId,
        name: "ДОД Админ",
        menu: dodMenu
    },
    {
        id: superAdminId,
        name: "Суперпользователь",
        menu: superAdminMenu
    },
    {
        id: mentorId,
        name: "Ментор",
        menu: mentorMenu
    },
    {
        id: curatorId,
        name: "Куратор",
        menu: curatorMenu
    },
    {
        id: departmentStaffId,
        name: "Сотрудник факультета",
        menu: departmentStaffMenu
    },
    {
        id: viceDepartmentStaffId,
        name: "Замдекана",
        menu: viceDepartmentStaffMenu
    },
    {
        id: officeManager,
        name: 'Менеджер офиса',
        menu: officeManagerMenu
    }
]

export const journalName = 'Электронный журнал';
export const deadlinesName = 'Дедлайны';
export const exportImportName = 'Экспорт/Импорт';
export const reportsName = 'Отчеты';
export const userChangeName = 'Переключение пользователя';
export const flowConnectionsName = 'Связи потоков';
export const timetableEditorName = 'Редактор расписания';
export const roleEditorNameName = 'Управление ролями';
export const systemMessageName = 'Системное сообщение';
export const barsTableName = 'Таблицы БаРС';
export const osEditorName = 'Создание типа ОС';
export const testEditorName = 'Создание теста ЦДО';
export const accessEditorName = 'Управление доступом';
export const rpdImportName = 'Импорт РПД';

export const defaultTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

export const tableLocalization = (isLoading) => {
    return {
        header: {
            actions: "Действия",
        },
        body: {
            emptyDataSourceMessage: isLoading ? "Загрузка..." : "Нет записей для отображения"
        },
        toolbar: {
            searchTooltip: "Поиск",
            searchPlaceholder: "Поиск"
        },
        pagination: {
            nextAriaLabel: "След. страница",
            nextTooltip: "След. страница",
            previousTooltip: "Пред. страница",
            previousAriaLabel: "Пред. страница",
            firstAriaLabel: "Первая страница",
            firstTooltip: "Первая страница",
            lastAriaLabel: "Последняя страница",
            lastTooltip: "Последняя страница",
            labelRowsPerPage: "Записей на странице:",
            labelRowsSelect: "записей",
            labelDisplayedRows: "{from}-{to} из {count}"
        }
    }
}

export const paType = "pa";
export const paRetryType = "paRetry";
export const paSecondRetryType = "paSecondRetry";
export const paCourseType = "paCourse";
export const paCourseRetryType = "paCourseRetry";
export const paCourseSecondRetryType = "paCourseSecondRetry";

export const passed = 'Зачтено';
export const notPassedZachet = "Незачет";
export const notPassed = 'Неудовлетворительно, FX';
export const passedOk = 'Удовлетворительно, E';
export const passedBetterOk = 'Удовлетворительно, D';
export const passedGood = 'Хорошо, C';
export const passedBetterGood = 'Хорошо, B';
export const passedPerfect = 'Отлично, А';
export const absent = 'Неявка';

export const lection = 'Лекция';
export const practic = 'Практика';
export const laboratory = 'Лабораторная работа';

export const realisatorInstruction = `${process.env.PUBLIC_URL}/static/files/realisator_instruction.pdf`;
export const parentInstruction = `${process.env.PUBLIC_URL}/static/files/parent_instruction.pdf`;
export const studentInstruction = `${process.env.PUBLIC_URL}/static/files/student_instruction.pdf`;
export const departmentStaffInstruction = `${process.env.PUBLIC_URL}/static/files/department_instruction.pdf`;
export const viceDepartmentStaffInstruction = `${process.env.PUBLIC_URL}/static/files/zamdekan_instruction.pdf`;