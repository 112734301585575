import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getUserRoles = (id) => {
    return barsAxios.get(buildBackendUrl(`/rest/users/${id}/roles`))
}

const saveUserRoles = (id, data) => {
    return barsAxios.post(buildBackendUrl(`/rest/users/${id}/roles`), data);
}

const setUserRole = (data) => {
    return barsAxios.post(buildBackendUrl(`/rest/users/set_selected_role`), data);
}

const getUsersByRole = (roleId, searchTerm) => {
    let params = '';

    if (searchTerm) {
        params = `?filter=${searchTerm}`
    }

    return barsAxios.get(buildBackendUrl(`/rest/users/by_role/${roleId}` + params));
}

const getGroupsAndFlowsByName = (deadlineType, searchTerm = "", realizerPeopleId = "") => {
    let params = [];

    if (searchTerm) {
        params.push(`filter=${searchTerm}`)
    }

    if (realizerPeopleId) {
        params.push(`realizerPeopleId=${realizerPeopleId}`)
    }

    return barsAxios.get(buildBackendUrl(`/rest/users/white_list/${deadlineType}?` + params?.join('&')))
}

export default {
    getUserRoles,
    saveUserRoles,
    setUserRole,
    getUsersByRole,
    getGroupsAndFlowsByName,
}

