import {
    GET_DISCIPLINES,
    GET_STORED_DISCIPLINES,
    GET_USER_DISCIPLINES,
    SET_USER_DISCIPLINES
} from "../actions/catalogActions";
import produce from "immer";
import _ from "lodash";

const initialState = {
    disciplines: null,
    userDisciplines: []
}

const catalogReducer = (state = initialState, action) => {
    const {payload} = action;
    switch(action.type) {
        case GET_DISCIPLINES:
            return produce(state, (draft) => {
                draft.disciplines = payload;
            })
        case GET_USER_DISCIPLINES:
            return produce(state, (draft) => {
                draft.userDisciplines = payload;
            })
        case SET_USER_DISCIPLINES:
            return produce(state, (draft)=> {
                let cachedDisciplines = JSON.parse(localStorage.getItem("cached_disciplines"));
                let data = { roleId: payload.role?.id, disciplines: payload.data }
                draft.userDisciplines = payload.data;
                // if (cachedDisciplines) {
                //     cachedDisciplines?.push(data);
                //
                // } else {
                //     data = [data];
                //     localStorage.setItem("cached_disciplines", JSON.stringify(data));
                //     draft.userDisciplines = payload;
                // }

            })
        case GET_STORED_DISCIPLINES:
            return produce(state, (draft) => {
                draft.userDisciplines = payload;
            })
        default:
            return state;
    }
}

export default catalogReducer;