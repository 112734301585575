import { buildBackendUrl } from "../utils/generalUtils";
import barsAxios from "./api_config";
import { buildQueryParamsString } from "./api_helpers";

let ajaxRequest = null;

const getDisciplineAccess = (id) => {
    return barsAxios.get(buildBackendUrl(`/rest/discipline/${id}/access`));
}

const setDisciplineAccess = (id, data) => {
    return barsAxios.post(buildBackendUrl(`/rest/discipline/${id}/access`), data);
}

const getUserDisciplines = ({
                                name = '',
                                identifier = '',
                                withCheckpointPlansOnly = false,
                                distinctByCheckpointPlans = false,
                                type = '',
                                size,
                            }) => {
    const paramsString = buildQueryParamsString({
        withCheckpointPlansOnly,
        distinctByCheckpointPlans,
        name,
        identifier,
        type,
        size,
    });

    if (ajaxRequest) {
        ajaxRequest.cancel();
    }

    ajaxRequest = barsAxios.CancelToken.source();

    return barsAxios.get(buildBackendUrl('/rest/journal/disciplines?' + paramsString), { cancelToken: ajaxRequest.token });
}

const getUserDisciplinesOld = ({
                                   name = '',
                                   identifier = '',
                                   withCheckpointPlansOnly = false,
                                   distinctByCheckpointPlans = false,
                                   type = '',
                                   size,
                               }) => {
    const paramsString = buildQueryParamsString({
        withCheckpointPlansOnly,
        distinctByCheckpointPlans,
        name,
        identifier,
        type,
        size,
    });

    if (ajaxRequest) {
        ajaxRequest.cancel();
    }

    ajaxRequest = barsAxios.CancelToken.source();

    return barsAxios.get(buildBackendUrl('/rest/disciplines?' + paramsString), { cancelToken: ajaxRequest.token });
}

const getDisciplinesWithRealizer = ({
                                        searchTerm,
                                        type,
                                        identifier,
                                        excludedIds,
                                        realizerPeopleId,
                                        cancelToken
                                    }) => {
    const paramsString = buildQueryParamsString({
        name: searchTerm,
        type,
        identifier,
        excludedIds,
        realizerPeopleId,
    });

    return barsAxios.get(buildBackendUrl('/rest/disciplines/realizer?' + paramsString), { cancelToken: cancelToken })
}

const getAllDisciplines = ({ name, type = "", identifier = "", excludedIds = [] }) => {
    const paramsString = buildQueryParamsString({
        filter: name,
        type,
        identifier,
        excludedIds
    });

    return barsAxios.get(buildBackendUrl('/rest/disciplines/all?' + paramsString))
}

const getDisciplineById = disciplineId => {
    return barsAxios.get(buildBackendUrl(`/rest/disciplines/${disciplineId}`));
}

const getDisciplines = (flowId = "") => {
    let flowParam = "";
    if (flowId) {
        flowParam = `?flowId=${flowId}`
    }

    return barsAxios.get(buildBackendUrl("/rest/disciplines_for_plan/" + flowParam));
}

export default {
    getDisciplineAccess,
    setDisciplineAccess,
    getUserDisciplines,
    getUserDisciplinesOld,
    getAllDisciplines,
    getDisciplineById,
    getDisciplinesWithRealizer,
    getDisciplines
}