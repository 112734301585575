import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getJournal = (planId, deadlineType, deadlineIdentifier, disciplineId) => {
    if (!planId) {
        return barsAxios.get(buildBackendUrl(`/rest/marks/${disciplineId}/${deadlineType}/${deadlineIdentifier}/auto`))
    }
    return barsAxios.get(buildBackendUrl(`/rest/marks/${planId}/${deadlineType}/${deadlineIdentifier}`))
}

const getJournalForStudent = (planId, deadlineType, deadlineIdentifier) => {
    return barsAxios.get(buildBackendUrl(`/rest/marks/${planId}/${deadlineType}/${deadlineIdentifier}/student`))
}

const getJournalMarks = (planId, deadlineType, deadlineIdentifier, studentId) => {
    return barsAxios.get(buildBackendUrl(`/rest/marks/${planId}/${deadlineType}/${deadlineIdentifier}/student/${studentId}`))
}

const setAdditionalPoints = (persPlanCheckpointId, studentId, mark) => {
    return barsAxios.post(buildBackendUrl(`/rest/marks/additional/${persPlanCheckpointId}/${studentId}`), mark)
}

const getMarks = (disciplineId) => {
    return barsAxios.get(buildBackendUrl(`/rest/marks/${disciplineId}/`))
}

const setPersonPlanMark = (deadlineType, deadlineIdentifier, markType, studentId, mark) => {
    return barsAxios.post(buildBackendUrl(`/rest/marks/${deadlineType}/${deadlineIdentifier}/${markType}/${studentId}`), mark)
}

const setPersonFinalMark = (persPlanCheckpointId, studentId, mark) => {
    return barsAxios.post(buildBackendUrl(`/rest/marks/final/${persPlanCheckpointId}/${studentId}`), mark)
}

const confirmMarks = (planId, deadlineType, deadlineIdentifier, studentId, attempt, course = false) => {
    let courseString = '';
    if (!!course) {
        courseString = ` ?course=true`;
    }
    return barsAxios.post(buildBackendUrl(`/rest/marks/${planId}/${deadlineType}/${deadlineIdentifier}/${studentId}/approval/${attempt}` + courseString))
}

const getMarkHistory = (checkpointPlanId, deadlineType, deadlineIdentifier, studentId, checkpointId) => {
    let checkpointIdParam = '';

    if (checkpointId) {
        checkpointIdParam = `?checkpointId=${checkpointId}`;
    }

    return barsAxios.get(buildBackendUrl(`/rest/marks/${checkpointPlanId}/${deadlineType}/${deadlineIdentifier}/student/${studentId}/history${checkpointIdParam}`))
}

const setAgreement = (planId) => {
    return barsAxios.post(buildBackendUrl(`/rest/marks/${planId}/student/agreement`))
}

const approveAll = (planId, type, identifier, attempt, students, course = false) => {
    let params = '';

    if (students) {
        params = `?studentIds=${students?.join(',')}`
    }

    if (course) {
        params += `&course=${course}`
    }

    return barsAxios.post(buildBackendUrl(`/rest/marks/${planId}/${type}/${identifier}/approval/${attempt}` + params))
}

const getApproveHistory = (type, identifier, approvalId) => {
    return barsAxios.get(buildBackendUrl(`/rest/marks/${type}/${identifier}/approval/${approvalId}/history`))
}

const getCheckpointPlans = ({ name }) => {
    let params = [];
    if (name) {
        params.push(name)
    }

    let paramsString = params.join("&");
    return barsAxios.get(buildBackendUrl(`/rest/journal/checkpoint-plans?` + paramsString))
}

const clearJournalCache = (year, term) => {
    return barsAxios.delete(buildBackendUrl(`/rest/journal/cache?term=${term}&year=${year}`))
}

export default {
    getJournal,
    getJournalForStudent,
    getJournalMarks,
    setAdditionalPoints,
    getMarks,
    setPersonPlanMark,
    setPersonFinalMark,
    confirmMarks,
    getMarkHistory,
    setAgreement,
    approveAll,
    getApproveHistory,
    getCheckpointPlans,
    clearJournalCache
}