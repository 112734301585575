import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const createNewToken = () => {
    return barsAxios.post(buildBackendUrl('/rest/registration/token'))
}

const deleteUserToken = (token) => {
    return barsAxios.delete(buildBackendUrl(`/rest/registration/token/${token}`))
}

const getUserTokens = () => {
    return barsAxios.get(buildBackendUrl('/rest/registration/token'))
}

const registerUserWithToken = (token, data) => {
    return barsAxios.post(buildBackendUrl(`/rest/registration/${token}`), data)
}

export default {
    createNewToken,
    deleteUserToken,
    getUserTokens,
    registerUserWithToken
}