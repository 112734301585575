export const educationPlanMessages = {
    DISCIPLINE_NOT_SPECIFIED_ERROR: "Дисциплина не указана",
    DUPLICATE_PLAN_ERROR: "План с такой дисциплиной уже существует",
    CHECKPOINTS_EMPTY_ERROR: "Необходимо добавить точки",
    CHECKPOINT_NAME_EMPTY_ERROR: "Необходимо указать название точки",
    CHECKPOINT_TYPE_EMPTY_ERROR: "Необходимо указать тип КТ",
    CHECKPOINT_MIN_GREATER_MAX_ERROR: "Минимальное значение баллов не может превышать максимальное значение",
    CHECKPOINT_MIN_LESS_THAN_ZERO: "Минимальное значение контрольной точки не может быть меньше нуля",
    CHECKPOINT_MAX_LESS_THAN_ZERO: "Максмальное значение контрольной точки не может быть меньше нуля",
    CHECKPOINT_DATEFROM_GREATER_DATETO_ERROR: "Дата начала не может быть позже даты окончания",
    CHECKPOINT_WEEK_NOT_SET: "Необходимо указать рекомендуемую неделю",
    FINAL_TYPE_NOT_SPECIFIED: "Необходимо указать тип промежуточной аттестации",
    FINAL_MIN_LESS_THAN_ZERO: "Минимальное значение промежуточной аттестации не может быть меньше нуля",
    FINAL_MIN_GREATER_THAN_MAX: "Минимальное значение промежуточной аттестации не может быть больше максимального значения",
    SUBCHECKPOINT_WEEK_NOT_SET: "Необходимо указать рекомнедуемую неделю комплексной КТ",
    SUBCHECKPOINT_NAME_EMPTY_ERROR: "Необходимо указать название точки в групповом разделе",
    SUBCHECKPOINT_MIN_GREATER_MAX_ERROR: "Минимальное значение баллов не может превышать максимальное значение в групповом разделе",
    SUBCHECKPOINT_MIN_LESS_THAN_ZERO: "Минимальное значение баллов не может быть меньше нуля в групповом разделе",
    SUBCHECKPOINT_MAX_LESS_THAN_ZERO: "Максимальное значение баллов не может быть меньше нуля в групповом разделе",
    SUBCHECKPOINT_DATEFROM_GREATER_DATETO_ERROR: "Дата начала не может быть позже даты окончания в групповом разделе",
}

export const personalPlanMessages = {
    EMPTY_DEADLINES_ERROR: "Проставлены не все дедлайны",
    DEADLINES_SAVE_SUCCESS: "Дедлайны успешно сохранены",
}

export const flowMessages = {
    FLOW_DELETE_ERROR: "Произошла ошибка при удалении связи",
    FLOW_DELETE_SUCCESS: "Удаление прошло успешно",
    FLOW_CREATE_SUCCESS: "Привязка прошла успешно",
}

export const authMessages = {
    LOGIN_ERROR: "Произошла ошибка во время авторизации"
}

export const settingMessages = {
    DUPLICATE_SETTING_ERROR: "Оценочное средство уже есть в справочнике",
    INVALID_SETTING_NAME_ERROR: "Задано пустое имя типа ОС",
    SETTING_SAVE_ERROR: "Ошибка при создании типа ОС",
    SETTING_SAVE_SUCCESS: "Успешно создан тип ОС",
}

export const generalMessages = {
    FETCH_DATA_ERROR: "При получении данных произошла ошибка",
    PROCESSING_ERROR: "Произошла ошибка обработки данных",
    SAVE_ERROR: "При сохранении произошла ошибка",
    SAVE_SUCCESS: "Сохранение прошло успешно"
}