import api_auth from "./auth";
import api_checkpoint_types from './checkpoint_types';
import api_config from "./config";
import api_deadlines from "./deadlines";
import api_disciplines from "./disciplines";
import api_flows from "./flows";
import api_general from "./general";
import api_groups from './groups';
import api_journal from './journal';
import api_parents from './parents';
import api_personal_plan from './personal_plan';
import api_plan from './plan';
import api_reports from './reports';
import api_roles from './roles';
import api_schedule from './schedule';
import api_tests from './tests';
import api_token_registration from './token_registration';
import api_import_export from './import_export';

export default {
    ...api_auth,
    ...api_checkpoint_types,
    ...api_config,
    ...api_deadlines,
    ...api_disciplines,
    ...api_flows,
    ...api_general,
    ...api_groups,
    ...api_journal,
    ...api_parents,
    ...api_personal_plan,
    ...api_plan,
    ...api_reports,
    ...api_roles,
    ...api_schedule,
    ...api_tests,
    ...api_token_registration,
    ...api_import_export
}