import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getStudentSession = (planId, type, identifier, mode) => {
    let modeParam = "";
    if (mode === "course") {
        modeParam = "?course=true"
    }
    return barsAxios.get(buildBackendUrl(`/rest/session/${planId}/${type}/${identifier}${modeParam}`));
}

const setCustomScheduleDate = (planId, data) => {
    return barsAxios.post(buildBackendUrl(`/rest/session/${planId}`), data)
}

const setCustomScheduleForAll = (checkpointPlanId, type, identifier, data) => {
    return barsAxios.post(buildBackendUrl(`/rest/session/${checkpointPlanId}/${type}/${identifier}`), data);
}

export default {
    getStudentSession,
    setCustomScheduleDate,
    setCustomScheduleForAll
}