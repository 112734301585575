import React from 'react';
import {ToastContainer} from "react-toastify";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    toastContainer: {
        fontFamily: 'ALS Schlange sans',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16
    }
}))

const ToastDefaultContainer = () => {
    const classes = useStyles();

    return <ToastContainer
        position="top-right"
        style={{fontFamily: 'ALS Schlange sans',
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16}}
        autoClose={5000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
}

export default ToastDefaultContainer;