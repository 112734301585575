import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import api from "../api/api";
import {setUserData} from "../actions/accountActions";
import { useHistory } from "react-router";
import { SSO_URL } from "../config";

const AuthGuard = (props) => {
    const {children} = props;
    const [loading,setLoading] = useState(false);
    const account = useSelector((state) => state.account);
    const user = account?.user;
    const dispatch = useDispatch();
    const history = useHistory();

    const checkUser = () => {
        setLoading(true);
        api.getCurrentUser().then((response) => {
            dispatch(setUserData(response.data))
        }).catch((error) => {
            if (error.response?.status === 401) {
                localStorage.setItem('token', '');
                if (!history.location.pathname.includes('login')) {
                    window.location.replace(SSO_URL(history.location.pathname))
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (account?.user === null && !loading) {
            checkUser()
        }
    }, [])

    return <>
        {children}
    </>;
}

AuthGuard.propTypes = {
    children: PropTypes.any
};

export default AuthGuard;
