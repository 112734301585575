import moment from "moment";
import {
    curatorId, departmentStaffId, departmentStaffInstruction, didAdminId,
    dodAdminId, laboratory, lection, mentorId,
    parentId, parentInstruction, practic, realisatorInstruction,
    studentId, studentInstruction,
    superAdminId,
    teacherAdminId,
    teacherRealisatorId, userRoles, viceDepartmentStaffId, viceDepartmentStaffInstruction
} from "../constants";
import {
    adminMenu, curatorMenu,
    defaultMenu, departmentStaffMenu,
    dodMenu, mentorMenu, parentsMenu,
    realisatorMenu,
    studentMenu, superAdminMenu
} from "../layouts/DashboardLayout/NavBar/menus";
import {homeUrl} from "../constants/paths";
import {BACKEND_URL} from "../config";
import * as _ from 'lodash';

export const buildSearchUrl = (name = '',
                               page = '',
                               size = '',
                               sort = '',
                               start_date = '',
                               end_date = '',
                               paramsOnly = false) => {
    const input = [];

    if (name) {
        input.push(`name=${name}`)
    }

    if (page) {
        input.push(`page=${page}`)
    }

    if (size) {
        input.push(`size=${size}`)
    }

    if (sort) {
        input.push(`sort=${sort}`)
    }

    if (start_date && !isNaN(start_date)) {
        input.push(`start_date=${moment(start_date).valueOf()}`)
    }

    if (end_date && !isNaN(end_date)) {
        input.push(`end_date=${moment(end_date).valueOf()}`)
    }

    const result = input.join('&');

    return paramsOnly ? result : `bars_tables?${result}`
};

export const getValueFromConfig = (config, key) => {
    return config?.find(x => x.name === key)?.value;
}

export const convertStringBoolToBool = (value) => {
    return value?.toLowerCase() === "true";
}

export const navRouteSelect = (values) => {
    const roles = _.orderBy(values, ['id'], ['desc']);
    const unionMenu = [];

    roles?.filter(x => x.id !== didAdminId)?.map(role => {
        const roleMenu = userRoles.find(roleItem => roleItem.id === role.id)?.menu(role);
        unionMenu.push(roleMenu);
    })

    return unionMenu;
}

const getMenuHref = (menu) => {
    return menu.find(x => x)?.items.find(x => x)?.href;
}

export const getFirstMenuItem = (roleId) => {
    if (!roleId) {
        return getMenuHref(defaultMenu);
    }

    switch (roleId) {
        case (studentId):
            return getMenuHref(studentMenu);
        case (teacherAdminId):
            return getMenuHref(adminMenu);
        case (departmentStaffId):
            return getMenuHref(departmentStaffMenu);
        case (teacherRealisatorId):
            return getMenuHref(realisatorMenu);
        case (parentId):
            return getMenuHref(parentsMenu);
        case (dodAdminId):
            return getMenuHref(dodMenu);
        case (superAdminId):
            return getMenuHref(superAdminMenu);
        case(mentorId):
            return getMenuHref(mentorMenu);
        case(curatorId):
            return getMenuHref(curatorMenu);
        default:
            return homeUrl;
    }
}

export const getInstructionLink = (userRoleId) => {
    switch (userRoleId) {
        case (teacherRealisatorId):
            return realisatorInstruction;
        case (studentId):
            return studentInstruction;
        case (parentId):
            return parentInstruction;
        case (departmentStaffId):
            return departmentStaffInstruction;
        case(viceDepartmentStaffId):
            return viceDepartmentStaffInstruction;
        default:
            return '';
    }
}

export const getInstructionName = (userRoleId) => {
    switch(userRoleId){
        case (teacherRealisatorId):
            return "преподавателя-реализатора";
        case(studentId):
            return "студента";
        case(departmentStaffId):
            return 'сотрудника факультета';
        case (parentId):
            return "родителя/официального представителя";
        case (teacherAdminId):
            return "преподавателя-администратора";
        case (mentorId):
            return "ментора";
        case (curatorId):
            return "куратора";
        case (superAdminId):
            return "супер пользователя";
        case (dodAdminId):
            return "сотрудника ДОД";
        case (viceDepartmentStaffId):
            return "замдекана"
        default:
            return "";
    }
}

export const minutesToMs = (value) => {
    return parseFloat(value) * 60000;
};

export const getFlowType = (flowType) => {
    switch (flowType) {
        case "lection":
            return lection;
        case "practic":
            return practic;
        case "laboratory":
            return laboratory;
        default:
            return "";
    }
}

export const renderUserName = (option) => {
    if (!option) {
        return ""
    }

    let name = `${option.id} /`;
    if (option.last_name) {
        name += ` ${option.last_name}`;
    }

    if (option.first_name) {
        name += ` ${option.first_name}`;
    }

    if (option.middle_name) {
        name += ` ${option.middle_name}`;
    }

    return name;
}

export const buildBackendUrl = (url) => {
    const backend_address = BACKEND_URL;
    if (url.startsWith("/")) {
        return backend_address + url
    } else {
        return backend_address + "/" + url;
    }
}

export const getSelectedCheckpointId = (selectedDiscipline, selectedGroup) => {
    return selectedDiscipline?.checkpoint_plan_ids.filter(x => selectedGroup?.checkpoint_plan_ids.includes(x)).find(x => x)
}

