import {buildBackendUrl, buildSearchUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getPlanById = (id) => {
    return barsAxios.get(buildBackendUrl(`/rest/checkpoint_plans/${id}`), {withCredentials: true, mode: 'cors'});
}

const getPlansByDisciplineAndPrograms = (disciplineId, programs = '') => {
    let params = "";

    if (Array.isArray(programs)) {
        params = `?programIds=${programs.join(',')}`
    }

    return barsAxios.get(buildBackendUrl(`/rest/checkpoint_plans/discipline/${disciplineId}` + params))
}

const createPlan = (data) => {
    return barsAxios.post(buildBackendUrl("/rest/checkpoint_plans"), data);
}

const updatePlan = (data) => {
    return barsAxios.put(buildBackendUrl(`/rest/checkpoint_plans/${data.id}`), data)
}

const deletePlan = async (id) => {
    return await barsAxios.delete(buildBackendUrl(`/rest/checkpoint_plans/${id}`));
}

const searchPlans = (name, page, size, sort, start_date, end_date) => {
    const params = buildSearchUrl(name, page, size, sort, start_date, end_date, true);

    return barsAxios.get(buildBackendUrl(`/rest/checkpoint_plans?` + params))
}

const importRpd = (id) => {
    return barsAxios.get(buildBackendUrl(`/rest/checkpoint_plans/rpd/constructor/import?ids=${id}`))
}

export default {
    getPlanById,
    getPlansByDisciplineAndPrograms,
    createPlan,
    updatePlan,
    deletePlan,
    searchPlans,
    importRpd,
}