import React, { useRef } from 'react';
import {
    IconButton,
    SvgIcon,
    Tooltip,
} from '@material-ui/core';
import { LogOut } from 'react-feather';
import { logout } from "../../../actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../api/api";
import { useHistory } from "react-router";

function Logout() {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.account)
    const ref = useRef(null);
    const history = useHistory()
    const handleLogout = () => {
        dispatch(logout())
        api.userLogout().then(() => localStorage.removeItem('token'))
            .catch(() => localStorage.removeItem('token'))
            .finally(() => window.location = process.env.REACT_APP_SSO_LOGOUT_URI)
    };

    return (
        <>
            {user && <Tooltip title="Выход">
                <IconButton
                    color="inherit"
                    onClick={handleLogout}
                    ref={ref}
                >
                    <SvgIcon fontSize="small">
                        <LogOut/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>}
        </>
    );
}

export default Logout;
