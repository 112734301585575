import api from "../api/api";
import { toast } from "react-toastify";

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const REGISTER_FAILURE = '@account/register-failure'
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';
export const SET_AUTH_TOKEN = '@account/set-token';

export function login(login, password, sso = false) {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGIN_REQUEST });

            const form = {
                login: login,
                password: password
            }

            try {
                if (!!sso) {
                    api.getUserLogin()
                        .then()
                        .catch()
                } else {
                    api.userLogin(form)
                        .then((response) => {
                            setUserData();
                            dispatch(setUserToken(response.headers.authorization))
                                .then(() => {
                                    dispatch(setUserData())
                                })
                        }).catch((error) => {
                        switch (error?.response?.status) {
                            case 404:
                                return toast.error('Пользователь не найден');
                            case 401:
                                return toast.error("Проверьте правильность логина / пароля");
                            default:
                                return toast.error('Произошла ошибка авторизации');
                        }
                    })
                }

            } catch (error) {
                dispatch({ type: LOGIN_FAILURE });
                throw error;
            }


        } catch (error) {
            dispatch({ type: LOGIN_FAILURE });
            throw error;
        }
    };
}

export const setUserData = () => {
    return async (dispatch) => {
        try {
            api.getCurrentUser()
                .then((response) => {
                    setUserToken(response.headers.authorization)
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                            user: response.data
                        }
                    });
                });
        } catch {

        }
    }

}

export const setUserToken = (token) => {
    localStorage.setItem("token", token);
    return async (dispatch) => dispatch({
        type: SET_AUTH_TOKEN,
        payload: {
            token
        }
    })
}

export const getTokenFromStore = () => {
    return (dispatch, getState) => {
        const state = getState();
        return state.account.token;
    }
}

export function logout() {
    return async (dispatch) => {
        dispatch({
            type: LOGOUT
        });
    };

}

export function register(token, data) {
    return async (dispatch) => {
        try {
            await api.registerUserWithToken(token, data);
        } catch (error) {
            dispatch({
                type: REGISTER_FAILURE
            })
            throw error;
        }
    }
}
