/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {useLocation, matchPath, useHistory} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    ListSubheader,
    makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import api from "../../../api/api";
import {navRouteSelect} from "../../../utils/generalUtils";
import {barsDashboard} from "../../../constants/paths";

function renderNavItems({items, ...rest}) {
    return (
        <List disablePadding>
            {items?.reduce(
                (acc, item) => reduceChildRoutes({acc, item, ...rest}),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({acc, pathname, item, depth = 0}) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                info={item.info}
                title={item.title}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    }
}));

function NavBar({openMobile, onMobileClose,}) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const {user} = useSelector((state) => state.account);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }

        if (user && !user?.selected_role?.id && user?.user_roles?.length > 0) {
            api.setUserRole(user?.user_roles?.find(x => x.id)).then(() => history.go('/bars'))
        }
        // eslint-disable-next-line
    }, [location.pathname, user]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Hidden lgUp>
                    <Box
                        p={2}
                        display="flex"
                        justifyContent="center"
                    >
                        <RouterLink to={barsDashboard}>
                            <Logo/>
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    {user && navRouteSelect(user?.user_roles)?.map((config) => (
                        <List
                            key={Math.random()}
                            subheader={(
                                <ListSubheader
                                    disableGutters
                                    disableSticky
                                >
                                    {config?.subheader}
                                </ListSubheader>
                            )}
                        >
                            {renderNavItems({items: config?.items, pathname: location?.pathname})}
                        </List>
                    ))}
                </Box>
                <Divider/>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Drawer
                anchor="left"
                classes={{paper: classes.mobileDrawer}}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
            >
                {content}
            </Drawer>
        </>
    );
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
