import React from 'react';
import {CircularProgress} from "@material-ui/core";
import './loaders.scss';

const CircularLoader = (props) => {
    return (
        <div className={'loader-container'}>
            <CircularProgress className={'loader'}/>
        </div>
    );
}

export default CircularLoader;