import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const importJournal = (spreadSheetUrlWithSheetGid, validateOnly = false) => {
    let url = encodeURI(spreadSheetUrlWithSheetGid).replace('#', "%23");
    if (validateOnly) {
        url += `&validateOnly=${validateOnly}`
    }

    return barsAxios.get(buildBackendUrl(`/rest/google/import/sheet?spreadSheetUrlWithSheetGid=${url}`))
}

const exportJournal = (params) => {
    let keys = Object.keys(params);
    let paramStrings = keys.map((key) => {
        return params[key] ? `${key}=${params[key]}` : ''
    })?.filter(x => x)?.join('&');
    return barsAxios.get(buildBackendUrl('/rest/google/export/sheet?' + paramStrings))
}

export default {
    importJournal,
    exportJournal
}