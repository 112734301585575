
export const buildQueryParamsString = (params) => {
    let paramsContainer = [];
    let keys = Object.keys(params);

    keys.forEach(key => {
        let paramString = ``;
        if (params[key]) {
            paramString = Array.isArray(params[key])
                ? `${key}=${params[key].join(',')}`
                : `${key}=${params[key]}`;
            paramsContainer.push(paramString);
        }
    });

    return paramsContainer.join('&');
}