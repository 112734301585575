import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getReport = (reportName = 'current_control', filters = {}) => {
    return barsAxios.post(buildBackendUrl(`/rest/report/${reportName}`), filters)
}

const getReportFile = (reportName = 'current_control', filters = {}) => {
    let name = 'current_control';
    if (reportName) {
        name = reportName
    }
    return barsAxios.post(buildBackendUrl(`/rest/report/${name}/export`), filters, {responseType: 'arraybuffer'})
}

const getReportTypes = () => {
    return barsAxios.get(buildBackendUrl('/rest/report/type'), {headers: {"Content-Type": 'application/json'}});
}

export default {
    getReport,
    getReportFile,
    getReportTypes
}