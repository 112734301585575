import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";
import {SSO_LOGOUT_URL} from "../constants";

const userLogin = (loginData) => {
    return barsAxios.post(buildBackendUrl(`/rest/login`), loginData)
}

const userLogout = () => {
    return barsAxios.get(SSO_LOGOUT_URL)
}

const getCurrentUser = () => {
    return barsAxios.get(buildBackendUrl(`/rest/users/current_user/`), {withCredentials: true, mode: 'no-cors'});
}

const getUserLogin = (code = "", customRedirectUri) => {
    let params = '';

    if (code) {
        params = `?code=${code}`;
    }

    if (customRedirectUri) {
        params += `&customRedirectUri=${customRedirectUri}`
    }
    return barsAxios.get(buildBackendUrl('/rest/login' + params), { withCredentials: true });
}

const setUser = (number) => {
    return barsAxios.get(buildBackendUrl(`/rest/set_me_to/${number}`));
}

export default {
    userLogin,
    userLogout,
    getCurrentUser,
    getUserLogin,
    setUser
}