export const ENABLE_LOADER = '@loader/enable';
export const DISABLE_LOADER = '@loader/disable';

export const enableLoader = () => {
    return async (dispatch) => {
        dispatch({
            type: ENABLE_LOADER,
            payload: {
                loader: true
            }
        });
    }
}

export const disableLoader = () => {
    return async (dispatch) => {
        dispatch({
            type: DISABLE_LOADER,
            payload: {
                loader: false
            }
        })
    }
}