import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getAllUsers = (searchTerm) => {
    let params = '';

    if (searchTerm) {
        params = `?filter=${searchTerm}`
    }
    return barsAxios.get(buildBackendUrl("/rest/users" + params));
}

const getStudents = (name = '') => {
    const param = name ? `?filter=${name}` : "";
    return barsAxios.get(buildBackendUrl(`/rest/users/students` + param))
}

const getEducationProgram = (disciplineId, terms) => {
    let termsParam = 'term=null';
    if (terms) {
        termsParam = Array.isArray(terms) ? `term=${terms?.join(',')}` : `term=${terms}`;
    }

    return barsAxios.get(buildBackendUrl(`/rest/educational_programs/${disciplineId}?${termsParam}`))
}

export default {
    getAllUsers,
    getStudents,
    getEducationProgram
}

