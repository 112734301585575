import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";
import { buildQueryParamsString } from "./api_helpers";

const getFlowLinks = (name = '') => {
    let params = '';

    if (name) {
        params = `?name=${name}`
    }

    return barsAxios.get(buildBackendUrl(`/rest/flows/plans` + params))
}

const getFlows = ({ name = '', all = false }) => {
    let params = buildQueryParamsString({ name, all })
    return barsAxios.get(buildBackendUrl(`/rest/flows?` + params))
}

const saveFlowTableLink = (flowId, planId) => {
    return barsAxios.post(buildBackendUrl(`/rest/flows/${flowId}/plans/${planId}`))
}

const deleteFlowTableLink = (flowId, planId) => {
    return barsAxios.delete(buildBackendUrl(`/rest/flows/${flowId}/plans/${planId}`))
}

export default {
    getFlowLinks,
    getFlows,
    saveFlowTableLink,
    deleteFlowTableLink
}