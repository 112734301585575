/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    ENABLE_LOADER,
    DISABLE_LOADER,
} from "../actions/loaderActions";

const initialState = {
    loader: false
}

const loaderReducer = (state = initialState, action) => {

    switch (action.type) {
        case ENABLE_LOADER: {
            return produce(state, (draft) => {
                const {loader} = action.payload;
                draft.loader = loader;
            });
        }
        case DISABLE_LOADER: {
            return produce(state, (draft) => {
                const {loader} = action.payload;
                draft.loader = loader;
            });
        }
        default: {
            return state;
        }
    }
}

export default loaderReducer;