export const mainPageContentUrl = '/main';
export const barsDashboard = `/bars/dashboard`;
export const settingsUrl = '/bars/settings/create_type';
export const testUrl = '/bars/settings/create_test'
export const systemMessagesUrl = '/bars/system_messages';
export const journalExportImport = '/bars/journal_export_import'

export const barsTablesUrl = '/bars/bars_tables';
export const barsPlanConstructor = '/bars/plan/'
export const deadlinesUrl = '/bars/deadlines'
export const reportsUrl = '/bars/reports'
export const parentsUrl = '/bars/parents';
export const systemManagementUrl = '/bars/system_management';
export const flowLinksUrl = '/bars/flow_connections/';
export const homeUrl = '/bars/default';
export const adminPanelUrl = '/bars/admin_panel';
export const teacherDisciplineConnectionUrl = '/bars/discipline_connection'
export const rpdImportUrl = '/bars/rpd_import';

export const disciplinesPageUrl = '/bars/disciplines';
export const journalPageUrl = '/bars/journal/';
export const studentJournalPageUrl = '/bars/student/journal/';
export const parentAccess = '/bars/parent/';
export const personalPlanUrl = '/bars/personal_plan/'

export const intermediateCertificationDateEditorUrl = '/bars/intermediate_certification_dates'

export const mainPageUrl = '/bars/default';

export const superAdminPageUrl = '/bars/super_admin';