import {buildBackendUrl} from "../utils/generalUtils";
import barsAxios from "./api_config";

const getParents = (studentId) => {
    return barsAxios.get(buildBackendUrl(`/rest/users/${studentId}/parent`));
}

const addParent = (studentId, passwordData) => {
    return barsAxios.post(buildBackendUrl(`/rest/users/${studentId}/parent`), passwordData)
}

export default {
    getParents,
    addParent
}