import React, { useEffect, useState } from 'react';
import { Grid, InputBase, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { current_term, current_year, terms } from "../../../constants";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../../api/api";
import { setUserData } from "../../../actions/accountActions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles(() => ({
    termContainer: {
        backgroundColor: "white",
        zIndex: 2,
        position: 'fixed',
        top: 64,
        width: '100%',
        paddingLeft: 40
    },
    textField: {
        fontSize: 18,
        fontFamily: 'ALS Schlange sans',
        '&:before': {
            content: 'none'
        },
        '&:after': {
            border: 'none',
            borderBottom: 'none',
        }
    }
}))

const getYearsList = () => {
    // july
    const studyYearStartMonth = 6
    const firstYear = 2019
    
    const hasMoreYear = (year, now) => now.getFullYear() > year ||
        (now.getFullYear() === year && now.getMonth() >= studyYearStartMonth)

    const getStudyYearObject = (year) => ({value: `${year}/${year + 1}`, name: `${year}/${year + 1}`})

    const getYearRecursive = (year, result) => {
        if (hasMoreYear(++year, new Date()))
            return getYearRecursive(year, [...result, getStudyYearObject(year)])

        return result
    }

    return getYearRecursive(firstYear, [getStudyYearObject(firstYear)])
}

const TermChange = () => {
    const [personalYear, setPersonalYear] = useState('');
    const [personalTerm, setPersonalTerm] = useState('');
    const account = useSelector((state) => state.account);
    const { user } = account;
    const { systemConfig } = useSelector(state => state)

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (user) {
            if (user?.selected_year) {
                setPersonalYear(user?.selected_year)
            }

            if (user?.selected_term !== null) {
                setPersonalTerm(user?.selected_term);
            }

            if (user?.personal_config?.find(x => x.name === current_term)?.value) {
                setPersonalTerm(user?.personal_config?.find(x => x.name === current_term)?.value)
            }

            if (user?.personal_config?.find(x => x.name === current_year)?.value) {
                setPersonalYear(user?.personal_config?.find(x => x.name === current_year)?.value)
            }
        }
    }, [user])

    const handleYearChange = (value) => {
        setPersonalYear(value);
        switchTermAndYear({ year: value });
    }

    const handlePersonalTermChange = (value) => {
        setPersonalTerm(value);
        switchTermAndYear({ term: value })
    }

    const switchTermAndYear = ({ year, term }) => {
        const dataTerm = {
            name: "current_term",
            value: term ?? personalTerm
        }

        const dataYear = {
            name: "current_year",
            value: year ?? personalYear
        }

        api.setPersonalConfig(dataTerm)
            .then(() => {
                dispatch(setUserData());
            })
            .catch(() => toast.error("Не удалось изменить семестр/год"))
            .finally(() => {
                api.setPersonalConfig(dataYear)
                    .then(() => {
                        dispatch(setUserData());
                        if (location.pathname.includes('journal')) {
                            history.push('/bars/journal');
                        }
                        history.go();
                    })
            })
    }

    const renderYearName = (item) => {
        return systemConfig?.config?.find(x => x.name === current_year)?.value === item.value
            ? item.name + " (текущий)"
            : item.name
    }

    return (
        <div className={classes.termContainer}>
            <Grid container direction={"row"} alignItems={"flex-end"} alignContent={"center"} spacing={3}>
                <Grid item xs={2}>
                    <Select
                        classes={{ root: classes.textField }}
                        input={<InputBase/>}
                        id={"term_select"}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            className: classes.textField,
                        }}
                        placeholder={"Семестр"}
                        fullWidth
                        name="Семестр"
                        value={personalTerm}
                        onChange={(e) => handlePersonalTermChange(e.target.value)}
                        variant={"standard"}
                    >
                        {terms.map(item =>
                            <MenuItem
                                key={item.id}
                                value={item.value}
                            >
                                {item.name}
                            </MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <Select
                        classes={{ root: classes.textField }}
                        input={<InputBase/>}
                        id={"year_select"}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            className: classes.textField,
                        }}
                        placeholder={"Год"}
                        fullWidth
                        name="Год"
                        value={personalYear}
                        onChange={(e) => handleYearChange(e.target.value)}
                        variant={"standard"}
                    >
                        {getYearsList().map(item =>
                            <MenuItem
                                key={item.value}
                                value={item.value}
                            >
                                {renderYearName(item)}
                            </MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid>
        </div>
    );
}

export default TermChange;