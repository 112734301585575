/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    CONFIG_REQUEST,
    CONFIG_SUCCESS,
    CONFIG_FAILURE
} from "../actions/configActions";

const initialState = {
    config: null
}

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIG_REQUEST: {
            return produce(state, (draft) => {
                draft.config = null;
            });
        }
        case CONFIG_SUCCESS: {
            const {config} = action.payload;

            return produce(state, (draft) => {
                draft.config = config;
            });
        }
        case CONFIG_FAILURE: {
            return produce(state, (draft) => {
                draft.config = null;
            })
        }
        default: {
            return state;
        }
    }
}

export default configReducer;